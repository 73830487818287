jQuery(document).ready(function($){

    function close_modals() {
        $('.top-bar__links__item.active').each(function() {
            $(this).removeClass('active');
            var modal_id = $(this).find('a').attr('href');
            var pos = modal_id.indexOf('#');

            if (pos != -1) {
                var sel = '#modal-' + $(this).find('a').attr('href').substring(pos + 1);
                $(sel).slideToggle('fast');
            }
        });

        if ($('#modal-fixtures:visible, #modal-tools:visible').length && $('.menu-overlay:visible').length) {
            $('.menu-overlay').fadeToggle('fast');
        }

    }

    $(".top-bar__links__item").on("mouseover", function (event) {
        if ($(this).hasClass("active") || whatInput.ask() == "touch")
            return;

        var $el = $(this).children('a');

        if ($el.length) {
            close_modals();
            $(this).addClass("active");

            var modal_id = $el.attr('href');
            var pos = modal_id.indexOf('#');

            if (pos != -1) {
                var sel = '#modal-' + modal_id.substring(pos + 1);
                $(sel).slideToggle('fast');
                $('.menu-overlay').fadeToggle('fast');
            }
        }
    });

    $(".top-bar__links__item > a[href^='#']").click(function (event) {
        event.preventDefault();
        event.stopPropagation();

        var modal_id = $(this).attr('href');
        var pos = modal_id.indexOf('#');

        if (pos != -1) {
            var sel = '#modal-' + modal_id.substring(pos + 1);
            $(sel).slideToggle('fast');
        }

        var $parent = $(this).parents('.top-bar__links__item');

        if (!$parent.hasClass('active')) {
            close_modals();
            $parent.addClass('active');
        } else {
            $('.top-bar__links__item').removeClass('active');
        }

        $('.menu-overlay').fadeToggle('fast');
    });

    $('.top-bar__modal *:not(a), .top-bar__links *:not(a)').click(function (event) {
        event.stopPropagation();
    });

    $('body').on('click', function() {
        close_modals();
    });

});
