jQuery(document).ready(function($){

    function refresh_results(main_cat, brand) {
        var ajax_data = {};

        $('.filters__item').each(function() {
            var cur_val = $(this).find('select').val();

            if (cur_val != 'all')
                ajax_data[$(this).data('filter')] = cur_val;
        });

        if (main_cat && !("product_cat" in ajax_data)) {
            ajax_data["product_cat"] = main_cat;
        }

        if (brand) {
            ajax_data["pa_marka"] = brand;
        }

        if (ajax_data) {
            ajax_data["action"] = "product_filter";

            if ($('.download-filters__wrap').length) {
                ajax_data["query_type"] = "download";
            }

            $.post(
                acfmaps.ajax_url,
                ajax_data,
                function (response) {
                    $('.search-noresults').hide();

                    var $panel = $('.products_filters__tabs__item.is-active > a');

                    if ($panel.length) {
                        var sel = "#" + $panel.attr("href").substr(1);
                        $panel = $(sel);
                        $panel.find('.filters__results').html(response.data);

                        if (response.data.length == 0) {
                            $panel.find('.search-noresults').slideToggle('medium');
                        }
                    } else {
                        $('.filters__results').html(response.data);

                        if (response.data.length == 0) {
                            $('.search-noresults').slideToggle('medium');
                        }
                    }
                }
            );
        }
    }

    $('.filters__item > select').change(function() {
        var main_cat = $(this).data('main_cat');
        var brand = $(this).data('brand');
        refresh_results(main_cat, brand);
    });

    var sel = $('.filters__item > select');

    if (sel.length) {
        var selectors = sel.map(function() { return $(this).val(); }).toArray();
        var need_refresh = !selectors.every(function(n) { return n == "all"; });

        if (need_refresh) {
            refresh_results(sel.eq(0).data('main_cat'), sel.eq(0).data('brand'));
        }
    }


});
