jQuery(document).ready(function($){

    $('.slider-home').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: false,
    });

    $('.product-upsells__slider').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: false,
        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 640,
                settings: "unslick",
            },
        ],
    });

    $('.home1__items').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: false,
        // prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        // nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });

    $('.cooperation-slider').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/schedpol/assets/images/slider-arrow-left.svg" alt="" /></button>',
        nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/schedpol/assets/images/slider-arrow-right.svg" alt="" /></button>',
        responsive: [
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 640,
                settings: "unslick",
            },
        ],
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                settings: "unslick",
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                }
            }
        ],
    });

});
