jQuery(document).ready(function($){

    function tense_get_stock_caption(product_id) {
        $.post(
            acfmaps.ajax_url,
            {
                action: 'get_stock_status',
                product_id: product_id,
            },
            function (response) {
                if (response.success) {
                    $('.product__stock').html(response.data.stock_status);
                }
            }
        );
    }

    $(document).on( 'found_variation', function( event, variation ) {
        $('.product__price__wrap--netto').html(variation.price_html);
        $('.product__price__wrap--brutto').html(variation.price_with_tax);

        if (variation.price_low) {
            $('[data-price-30days]').html(variation.price_low);
            $('.product__price--30days').removeClass('hide');
        } else {
            $('.product__price--30days').addClass('hide');
        }

        var url = $('.product__head-buttons__pdf').attr('href');
        url = url.replace(/print=1.*/, 'print=1&variation=' + variation.variation_id);
        $('.product__head-buttons__pdf').attr('href', url);

        // Get the current URL
        const current_url = new URL(window.location.href);
        current_url.searchParams.append('variation', variation.variation_id);

        // Update the browser's URL without reloading the page
        window.history.pushState({}, '', current_url);

        // if (variation.stock !== undefined) {
            // $('.product__stock').html(acfmaps.stock_caption + ' ' + variation.stock);
        // }

        if ($('.product__stock').length) {
            tense_get_stock_caption(variation.variation_id);
        }

        var tabs = ['specs', 'dimensions'];

        tabs.forEach(function(tab_name) {
            if ($('.product__' + tab_name).length) {
                $.post(
                    acfmaps.ajax_url,
                    {
                        action: 'get_variation_extra_' + tab_name,
                        variation_id: variation.variation_id,
                    },
                    function (response) {
                        $('.product__' + tab_name +' > tbody').html(response.data);
                    }
                );
            }
        });

        if ($('#bb-widget').length) {
            var $bb_widget = $('#bb-widget').eq(0);

            $bb_widget.attr('data-bb-number', variation.ean);
            $bb_widget.attr('data-bb-sku', variation.sku);
            $bb_widget.attr('data-bb-name', variation.name);
            $bb_widget.attr('data-bb-category', variation.category);
            $bb_widget.attr('data-bb-description', variation.desc);
            $bb_widget.attr('data-bb-price', variation.price_with_tax_raw);
            $bb_widget.attr('data-bb-price-bottom-limit', parseFloat(variation.price_with_tax_raw) - 1.5);
            $bb_widget.attr('data-bb-price-top-limit', parseFloat(variation.price_with_tax_raw) + 1.5);
            $bb_widget.attr('data-bb-image', variation.image.url);
        }

        if (window.bb !== undefined) {
            window.bb.forceReload();
        }

    });

    if ($('.product__stock[data-id]').length) {
        tense_get_stock_caption($('.product__stock').data('id'));
    }

});
